import React from "react";
import {
  Container,
  Description,
  MenuWpp,
  ContainerWeb,
  ContainerGrey,
  ContainerGeneral,
} from "./styles";

import HeaderWeb from "../../../components/HeaderWeb";

import Element from "../../../assets/elemento01_web.png";
import Iphone from "../../../assets/iphone1_web.png";
// import Whats from "../../../assets/Whats_web.png";
import Banner from "../../../components/Tela6/Rodape";
import elemento02 from "../../../assets/elemento08_web.png";
import wppIco from "../../../assets/wpp_web.svg";
import elemento03 from "../../../assets/elemento16_web.png";
import iphone2 from "../../../assets/iphone2_web.png";
import MenuLanguageWeb from "../../../components/MenuLanguageWeb";
import MenuWppWeb from "../../../components/MenuWppWeb";
import Section3 from "../../../components/Section3";
import Button from "../../../components/Button";
import Section5 from "../../../components/Section5";
import Section7 from "../../../components/Section7";
import FormGeneral from "../../../components/FormGeneral";
import Footer from "../../../components/Footer";
import TranslatorFunction from "../../../components/I18n/Translator";
import { Link } from "react-scroll";

function Web() {
  const theSmartestSolutionToPresentYourMenu = TranslatorFunction({
    path: "theSmartestSolutionToPresentYourMenu.message",
  });
  const digitalMenu = TranslatorFunction({ path: "digitalMenu.message" });
  const multilingual = TranslatorFunction({ path: "multilingual.message" });
  const ordersBy = TranslatorFunction({ path: "ordersBy.message" });
  const knowMore = TranslatorFunction({ path: "knowMore.message" });
  const myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast =
    TranslatorFunction({
      path: "myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast.message",
    });
  const noAppNeeded = TranslatorFunction({ path: "noAppNeeded.message" });
  const directAccessByQRCode = TranslatorFunction({
    path: "directAccessByQRCode.message",
  });
  const yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt =
    TranslatorFunction({
      path: "yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt.message",
    });
  const start = TranslatorFunction({ path: "start.message" });
  const sellMore = TranslatorFunction({ path: "sellMore.message" });
  const and = TranslatorFunction({ path: "and.message" });
  const save = TranslatorFunction({ path: "save.message" });
  const withPrintingCosts = TranslatorFunction({
    path: "withPrintingCosts.message",
  });
  const freeTrial = TranslatorFunction({ path: "freeTrial.message" });
  const days = TranslatorFunction({ path: "days.message" });
  const language = TranslatorFunction({ path: "language.message" });
  var tamanho = 70;
  switch (language) {
    case "pt":
      tamanho = 75;
      break;
    case "en":
      tamanho = 55;
      break;
    case "es":
      tamanho = 66;
      break;
    case "pt-PT":
      tamanho = 75;
      break;
    case "de":
      tamanho = 63;
      break;
    case "it":
      tamanho = 57;
      break;
    case "ja":
      tamanho = 55;
      break;
    case "zh-Hant":
      tamanho = 45;
      break;
    case "ko":
      tamanho = 62;
      break;
    case "ru":
      tamanho = 83;
      break;
    case "ar":
      tamanho = 38;
      break;
    default:
      tamanho = 70;
      break;
  }

  return (
    <>
      <ContainerGeneral>
        <Container>
          <a
            href="http://api.whatsapp.com/send?1=pt_BR&phone=5551989687886"
            target="_blank"
            rel="noreferrer"
            className="wpp"
          >
            <img src={wppIco} alt="" className="elemento02" />
          </a>
          <HeaderWeb />
          <Description id="about">
            <div className="web">
              <div className="title-buttons">
                <div className="titles">
                  <h1>{theSmartestSolutionToPresentYourMenu}</h1>

                  <MenuWpp>
                    <h3>
                      <b>
                        {digitalMenu} {multilingual}
                      </b>{" "}
                      + {ordersBy} <b>WhatsApp</b>
                    </h3>
                  </MenuWpp>

                  <h2>
                    {
                      myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast
                    }
                  </h2>
                </div>

                <div className="buttons">
                  <Link to="solution" smooth={true} className="more">
                    {knowMore}
                  </Link>
                  <Link to="register" smooth={true} className="start">
                    {start}
                  </Link>
                </div>
              </div>

              <div className="images">
                <img src={Element} alt="" className="elemento" />

                <img src={Iphone} alt="" className="iphone" />
              </div>
            </div>
          </Description>
          <MenuLanguageWeb />
          <MenuWppWeb />
        </Container>
        <ContainerGrey>
          <Section3 id={"solution"} />
        </ContainerGrey>
        <ContainerWeb id="functionality">
          <img src={elemento02} alt="" className="elemento02" />
          <img src={elemento03} alt="" className="elemento03" />
          <div className="picture">
            <img src={iphone2} alt="" />
          </div>
          <div className="texts">
            <h1>{noAppNeeded}</h1>
            <h1 className="accessQR">{directAccessByQRCode}</h1>
            <h2>
              {
                yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt
              }
            </h2>
            <h3 style={{ width: tamanho + "%" }}>
              <b>{sellMore} </b> {and} <b>{save}</b> {withPrintingCosts}
            </h3>
            <Button>
              <Link to="register" smooth={true} className="start">
                <h4>{freeTrial}</h4>
                <p>30 {days}</p>
              </Link>
            </Button>
          </div>
        </ContainerWeb>
        <Container>
          <Section5 id="whouse" />
        </Container>
        <Banner />
        <Section7 id={"price"} />
        <FormGeneral />
        <Footer />
      </ContainerGeneral>
    </>
  );
}

export default Web;
